import { Event, InlineCheckout } from '@bambora/checkout-sdk-web';
import { SingleEventHandler } from '@bambora/checkout-sdk-web/dist/src/events';
import React, { createContext, PropsWithChildren, useCallback, useEffect, useState } from 'react';

export interface IBamboraContext {
  createCheckoutSession(session: string, element: Element): void;
  cancelCheckoutSession(): void;
  status: PaymentStatus;
  resetStatusToIdle: () => void;
}

export enum PaymentStatus {
  Idle,
  InProgress,
  Success,
  Failure,
  Cancel
}

export const BamboraProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [checkout, setCheckout] = useState<InlineCheckout | null>(null);
  const [status, setStatus] = useState<PaymentStatus>(PaymentStatus.Idle);

  useEffect(() => {
    if (checkout) {
      checkout.on(
        Event.Authorize,
        async (payload) => {
          onTransactionComplete(payload);
        }
      );

      checkout.on(
        Event.Cancel,
        (payload) => {
          onTransactionCanceled(payload);
        }
      );

      checkout.on(
        Event.Close,
        (payload) => {
          setCheckout(null);
        }
      );
    }

    return () => {
      checkout?.destroy();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkout]);

  const onTransactionComplete: SingleEventHandler = useCallback(async (payload: any) => {
    setCheckout(null);
    setStatus(PaymentStatus.Success);
  }, []);

  const onTransactionCanceled: SingleEventHandler = useCallback(async () => {
    setCheckout(null);
    setStatus(PaymentStatus.Cancel);
  }, []);

  const createCheckoutSession = (session: string, element: Element) => {
    setCheckout(new InlineCheckout(session, { container: element }));
    setStatus(PaymentStatus.InProgress);
  }

  const cancelCheckoutSession = () => {
    checkout?.destroy();
    setCheckout(null);
    setStatus(PaymentStatus.Cancel);
  }

  const resetStatusToIdle = () => {
    setStatus(PaymentStatus.Idle);
  }

  const value: IBamboraContext = {
    createCheckoutSession,
    cancelCheckoutSession,
    status,
    resetStatusToIdle
  }

  return (
    <BamboraContext.Provider value={value}>
      {children}
    </BamboraContext.Provider>
  );
}

export const BamboraContext = createContext<IBamboraContext | null>(null);
