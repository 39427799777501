import { Immerable } from '../immerable';
import { RouteSchedule, RouteScheduleDto } from '../routes';

export interface BoatTripBookingSearchOutput {
  routeName: string;
  isReturnTrip: boolean;
  departureSchedules: Array<BookingQueryResultDto>;
  returnSchedules: Array<BookingQueryResultDto>;
}

export interface BoatTripBookingSearch {
  routeName: string;
  departureSchedules: Array<BookingQueryResult>;
  returnSchedules: Array<BookingQueryResult>;
}

export interface BookingQueryResultDto {
  departureSchedule: RouteScheduleDto;
  arrivalSchedule: RouteScheduleDto;
  departureHarbourName: string,
  arrivalHarbourName: string,
  price: number;
  bookedCapacity: number,
}

export class BookingQueryResult extends Immerable {
  departureSchedule: RouteSchedule;
  arrivalSchedule: RouteSchedule;
  departureHarbourName: string;
  arrivalHarbourName: string;
  price: number;
  bookedCapacity: number;

  constructor(data: BookingQueryResultDto) {
    super();
    this.departureSchedule = new RouteSchedule(data.departureSchedule);
    this.arrivalSchedule = new RouteSchedule(data.arrivalSchedule);
    this.departureHarbourName = data.departureHarbourName;
    this.arrivalHarbourName = data.arrivalHarbourName;
    this.price = data.price;
    this.bookedCapacity = data.bookedCapacity;
  }

  toJson(): BookingQueryResultDto {
    return {
      ...this,
      departureSchedule: this.departureSchedule.toJson(),
      arrivalSchedule: this.arrivalSchedule.toJson(),
    }
  }
}
