import { AsyncThunkAction } from '@reduxjs/toolkit';
import { useCallback, useEffect } from 'react';
import { RootState } from '../redux/store';
import { IStateSlice, LoadingState } from '../shared/interface';
import { useAppDispatch, useAppSelector } from './redux';

const fetchCondition = (stateSlice: IStateSlice<any>) => stateSlice.loading === LoadingState.IDLE;

export function useAutoFetchSelector<T>(
  stateSelector: (state: RootState) => IStateSlice<T>,
  fetchAction: AsyncThunkAction<any, any, any>,
  condition: (stateSlice: IStateSlice<T>) => boolean = fetchCondition
) {
  const dispatch = useAppDispatch();
  const stateSlice = useAppSelector(stateSelector);

  const boundAction = useCallback(() => {
    return dispatch(fetchAction);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, fetchAction]);

  useEffect(() => {
    if (condition(stateSlice)) {
      boundAction();
    }
  }, [boundAction, condition, stateSlice]);

  return stateSlice;
}
