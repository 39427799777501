import { createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { getApplicationConfiguration } from '../../service/application-configuration-api';

export interface ISettings {
  appConfig: any;
  tenantId: string;
  routeId: string;
  bookingChannel: string;
  language: string;
}

const settingsSlice = createSlice<ISettings, SliceCaseReducers<ISettings>, 'settings'>({
  name: 'settings',
  initialState: { appConfig: undefined, routeId: '', tenantId: '', bookingChannel: '', language: '' },
  reducers: {
    setAppConfig: (state, action) => {
      state.appConfig = action.payload;
    },
    setTenant: (state, action: PayloadAction<string>) => {
      state.tenantId = action.payload;
    },
    setRoute: (state, action: PayloadAction<string>) => {
      state.routeId = action.payload;
    },
    setBookingChannel: (state, action: PayloadAction<string>) => {
      state.bookingChannel = action.payload;
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    }
  }
});

export const fetchAppConfigAsync = createAsyncThunk(
  'app/fetchAppConfigAsync',
  async (_, { dispatch }) => {
    const appConfig = await getApplicationConfiguration();

    dispatch(setAppConfig(appConfig));
    dispatch(setTenant(appConfig.currentTenant.id));
  }
);

export const { setTenant, setRoute, setBookingChannel, setLanguage, setAppConfig } = settingsSlice.actions;
export default settingsSlice.reducer;