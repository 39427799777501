import I18n from 'i18n-js';
import { useEffect } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useRoutes } from '../../hooks';

export const RoutesDropdown = () => {
  const navigate = useNavigate();
  const { routeId } = useParams();
  const { data: routes } = useRoutes();

  useEffect(() => {
    if (routes.length && !routeId) {
      navigate(routes[0].id!);
    }
  }, [routeId, routes, navigate]);

  return (
    <NavDropdown title={I18n.t('Semestserbokningen::BoatTrips')} id="boat-trips-nav-dropdown">
      {routes.map(route => {
        return (
          <NavDropdown.Item key={route.id} onClick={() => navigate(route.id)}>
            {route.name}
          </NavDropdown.Item>
        );
      })}
    </NavDropdown>
  );
};
