import { Booking } from '../shared/interface';
import { useAppSelector } from './redux';
import { useRoute } from './use-routes';

/**
 * Calculates the price of a booking
 */
export const useRoutePrice = ({ departureTrip, returnTrip }: Booking): number => {
  const { numberOfAdults, numberOfChildren, routeId } = useAppSelector(state => state.boatTripsSearch.data.searchParams)!;
  const { discountChildren, discountSenior } = useRoute(routeId)!;

  let basePrice = departureTrip.price;
  let total = 0;

  if (returnTrip) {
    basePrice += returnTrip.price;
  }

  if (numberOfChildren > 0) {
    const discount = discountChildren ? discountChildren / 100 : 1;

    total += basePrice * numberOfChildren * discount;
  }

  if (numberOfAdults > 0) {
    total += basePrice * numberOfAdults;
  }

  return total;
}