import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { Booking } from '../../shared/interface';

const bookingSlice = createSlice<Booking | null, SliceCaseReducers<Booking | null>, 'booking'>({
  name: 'booking',
  initialState: null,
  reducers: {
    setBooking: (_state, action: PayloadAction<Booking>) => action.payload,
    reset: () => null,
  }
});

export const { setBooking, reset: resetBookingAction } = bookingSlice.actions;
export default bookingSlice.reducer;