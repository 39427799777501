import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import { Header } from '../../components/header/Header';

export const Start = () => {
  return (
    <>
      <Header></Header>
      <Container fluid>
        <Outlet />
      </Container>
    </>
  );
}