import I18n from 'i18n-js';
import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useAppSelector } from '../../../hooks';
import { LoadingState } from '../../../shared/interface';
import { BookingCard } from './BookingCard';

export const BoatTripSearchResult = () => {
  const { data, loading } = useAppSelector(state => state.boatTripsSearch);
  const routeName = data.searchResult?.routeName;

  useEffect(() => {
  }, [data?.searchParams]);

  if (loading === LoadingState.PENDING) {
    return (
      <div className='d-flex justify-content-center mt-3'>
        <Spinner animation='border' variant={'primary'} style={{ width: '4rem', height: '4rem' }}></Spinner>
      </div>
    );
  }

  if (loading === LoadingState.FAILED) {
    return <div>{I18n.t('::SearchFailed')}</div>;
  }

  return (
    <>
      {data.searchResult?.bookings.map((x, i) => <BookingCard key={i} booking={x} routeName={routeName!}></BookingCard>) ?? null}
    </>
  );
}