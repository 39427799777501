import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { ICheckoutForm } from '../../shared/interface';

export interface ISettings {
  tenantId: string;
  routeId: string;
  bookingChannel: string;
}

const initialState: ICheckoutForm = {
  email: '',
  name: '',
  surname: '',
  phoneNumber: '',
  payNow: true,
  termsAndConditions: true,
}

const checkoutFormSlice = createSlice<ICheckoutForm, SliceCaseReducers<ICheckoutForm>, 'checkoutForm'>({
  name: 'checkoutForm',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<ICheckoutForm>) => action.payload,
    reset: () => initialState,
  }
});

export const { set: setCheckoutFormAction, reset: resetCheckoutFormAction } = checkoutFormSlice.actions;
export default checkoutFormSlice.reducer;