import I18n from 'i18n-js';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../hooks';
import { createBooking, createBookingAndPayNow } from '../../service/booking';
import { ICheckoutForm, SearchParams } from '../../shared/interface';
import { CheckoutForm } from './components/CheckoutForm';
import { OrderSummary } from './components/OrderSummary';

export const Order = () => {
  const navigate = useNavigate();
  const bookingChannel = useAppSelector(state => state.settings.bookingChannel);
  const booking = useAppSelector(state => state.booking);
  const searchParams = useAppSelector(state => state.boatTripsSearch.data.searchParams) as SearchParams;
  const [loading, setLoading] = useState(false);

  const book = async (form: ICheckoutForm) => {
    setLoading(true);

    try {
      if (form.payNow) {
        const session = await createBookingAndPayNow(booking!, form, searchParams, bookingChannel);

        navigate(`/checkout/${session}`);
      } else {
        const res = await createBooking(booking!, form, searchParams, bookingChannel);

        if (res) {
          navigate(`/checkout/confirmation`);
        }
      }
    } catch (e) {
      return I18n.t('::Error:BookingFailed');
    } finally {
      setLoading(false);
    }

    return;
  }

  return (
    <>
      <div className='bg-dark py-5'>
        <h1 className='display-4 fw-bold text-light text-center'>
          {I18n.t('::OrderSummary')}
        </h1>
      </div>

      <Container fluid>
        <Row>
          <Col xs={12} md={6}>
            <CheckoutForm submit={book} loading={loading} />
          </Col>

          <Col xs={12} md={6}>
            <OrderSummary />
          </Col>
        </Row>
      </Container>
    </>
  );
}