import axios from './axios';
import { ROUTES } from './api-consts';
import { Route, RouteDto } from '../shared/interface';

export const getRoutes = async (): Promise<Array<Route>> => {
  const res = await axios.get<Array<RouteDto>>(ROUTES);

  return res.data?.map(x => new Route(x));
}

export const getRoute = async (id: string): Promise<Route> => {
  const res = await axios.get<RouteDto>(`${ROUTES}/${id}`);

  return new Route(res.data);
}