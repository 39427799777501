import { Route, Routes, MemoryRouter } from 'react-router-dom';
import { Start } from '../../pages/start/Start';
import { BoatTripSearch } from '../../pages/booking/boat-trips/Search';
import { Order } from '../../pages/order/Order';
import { BookingConfirmation } from '../../pages/order/BookingConfirmation';
import { Checkout } from '../../pages/checkout/Checkout';

export const Navigation = () => {
  return (
    <MemoryRouter>
      <Routes>
        <Route path="/" element={<Start />}>
          <Route path=":routeId" element={<BoatTripSearch />} />
        </Route>
        <Route path='checkout/order' element={<Order />} />
        <Route path='checkout/confirmation' element={<BookingConfirmation />} />
        <Route path='checkout/:session' element={<Checkout />} />
      </Routes>
    </MemoryRouter>
  );
}