import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './app/App';
import './app/App.scss';
import { BamboraProvider } from './app/contexts/Bambora.context';
import { store } from './app/redux/store';

const params = new URLSearchParams(window.location.search)

const tenantId = params.get('tenant-id');
const routeId = params.get('route-id') ?? '';
const bookingChannel = params.get('booking-channel') ?? '';
const language = params.get('lang') ?? '';

if (!tenantId) {
  throw new Error('Missing attribute "tenant-id"');
}

observeAndPostResizeEvents(document.documentElement);

const rootEl = document.getElementById('root');

if (!rootEl) {
  throw new Error('Missing element with id "root"');
}

rootEl.classList.add('pb-3');

const root = createRoot(rootEl);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BamboraProvider>
        <App tenantId={tenantId} routeId={routeId} bookingChannel={bookingChannel} language={language} />
      </BamboraProvider>
    </Provider>
  </React.StrictMode>
);

function observeAndPostResizeEvents(element: HTMLElement) {
  let lastReportedHeight: number;

  const resizeObserver = new ResizeObserver(entries => {
    const target = entries[0].target;

    // Avoid unnecessary updates
    if (lastReportedHeight === target.scrollHeight)
      return;

    lastReportedHeight = target.scrollHeight;

    // Get the exact height of the element
    const data = target.getBoundingClientRect().height;

    window.parent.postMessage(['setHeight', data], '*');
  });

  resizeObserver.observe(element);
}

