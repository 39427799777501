import { DateTime } from 'luxon';
import { EntityDto } from '../entity.interface';
import { Immerable } from '../immerable';

export interface CalendarItemDto extends EntityDto<string> {
  start: string;
  end?: string;
  weekDays: DayOfWeek[];
}

export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6
}

export class CalendarItem extends Immerable implements EntityDto<string> {
  id!: string;
  start!: DateTime;
  end?: DateTime;
  weekDays!: DayOfWeek[];

  constructor(data?: CalendarItemDto) {
    super();
    if (data) {
      this.id = data.id;
      this.start = DateTime.fromISO(data.start);
      this.end = data.end ? DateTime.fromISO(data.end) : undefined;
      this.weekDays = data.weekDays;
    }
  }
}
