import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import { SEARCH } from '../../service/api-consts';
import { search } from '../../service/booking';
import {
  BoatTripBookingSearch,
  Booking,
  IStateSlice,
  LoadingState,
  SearchParams,
} from '../../shared/interface';
import { RootState } from '../store';

interface BoatTripSearch {
  searchParams?: SearchParams | null;
  searchResult?: {
    routeName: string;
    bookings: Array<Booking>;
  };
}

export const searchBoatTripsAction = createAsyncThunk<
  BoatTripBookingSearch,
  SearchParams,
  { state: RootState }
>(SEARCH, search);

const initialState = {
  loading: LoadingState.IDLE,
  data: {
    searchParams: undefined,
    searchResult: undefined,
  },
};

const searchParamsSlice = createSlice<
  IStateSlice<BoatTripSearch>,
  SliceCaseReducers<IStateSlice<BoatTripSearch>>,
  'boatTripSearch'
>({
  name: 'boatTripSearch',
  initialState,
  reducers: {
    setSearchParams: (state, action: PayloadAction<SearchParams>) => {
      state.data.searchParams = action.payload;
    },
    reset: (state, action: PayloadAction) => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder.addCase(searchBoatTripsAction.pending, (state) => {
      state.loading = LoadingState.PENDING;
    });

    builder.addCase(searchBoatTripsAction.fulfilled, (state, action) => {
      state.loading = LoadingState.COMPLETE;
      state.data.searchResult = {
        routeName: action.payload.routeName,
        bookings: mapSearchResultToBookings(action.payload),
      };
    });

    builder.addCase(searchBoatTripsAction.rejected, (state, action) => {
      state.loading = LoadingState.FAILED;
      state.error = action.error.message;
      state.data.searchResult = undefined;
    });
  },
});

const mapSearchResultToBookings = ({
  departureSchedules,
  returnSchedules,
  routeName,
}: BoatTripBookingSearch): Array<Booking> => {
  const oneWayBooking = returnSchedules.length === 0;

  return departureSchedules.reduce((acc: Array<Booking>, departureTrip) => {
    let bookings: Array<Booking> = [];

    if (oneWayBooking) {
      bookings = [
        {
          routeName,
          departureTrip,
        },
      ];
    } else {
      // Possible return trips for this departure
      const returnTrips = returnSchedules.filter(
        (returnTrip) =>
          returnTrip.departureSchedule.partialRouteId ===
            departureTrip.arrivalSchedule.partialRouteId &&
          returnTrip.departureSchedule.departure! >
            departureTrip.arrivalSchedule.arrival!
      );

      bookings = returnTrips.map((returnTrip) => ({
        routeName,
        departureTrip,
        returnTrip,
      }));
    }

    return acc.concat(bookings);
  }, []);
};

export const {
  setSearchParams: setSearchParamsAction,
  reset: resetSearchAction,
} = searchParamsSlice.actions;

export default searchParamsSlice.reducer;
