import { EntityDto } from '../entity.interface';
import { CalendarItem, CalendarItemDto } from '../calendar/models';
import { ScheduleType } from './schedule-type.enum';
import { Immerable } from '../immerable';
import { DateTime } from 'luxon';

export interface RouteDto extends EntityDto<string> {
  name: string;
  cancelationPolicyId?: string;
  capacity: number;
  description?: string;
  discountChildren?: number;
  discountSenior?: number;
  isActive: boolean;
  partialRoutes: PartialRouteDto[];
  calendarItems: CalendarItemDto[];
  fullyBookedMessage: string;
}

export interface PartialRouteDto extends EntityDto<string> {
  routeId: string;
  harbourId: string;
  harbourName: string;
  order: number;
  priceCatalogs: PriceCatalogDto[];
  schedules: RouteScheduleDto[];
}

export interface PriceCatalogDto {
  id: string;
  fromPartialRouteId: string;
  toPartialRouteId: string;
  price: number;
}

export interface RouteScheduleDto extends EntityDto<string> {
  partialRouteId: string;
  order: number;
  scheduleType: ScheduleType;
  arrival?: string;
  departure?: string;
}

export class Route extends Immerable implements EntityDto<string> {
  id!: string;
  name!: string;
  cancelationPolicyId?: string;
  capacity!: number;
  description?: string;
  discountChildren?: number;
  discountSenior?: number;
  isActive!: boolean;
  partialRoutes!: Array<PartialRoute>;
  calendarItems!: Array<CalendarItem>;
  fullyBookedMessage?: string;

  constructor(data?: RouteDto) {
    super();
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.cancelationPolicyId = data.cancelationPolicyId;
      this.capacity = data.capacity;
      this.description = data.description;
      this.discountChildren = data.discountChildren;
      this.discountSenior = data.discountSenior;
      this.isActive = data.isActive;
      this.fullyBookedMessage = data.fullyBookedMessage;
      this.partialRoutes = data.partialRoutes?.map(partialRoute => new PartialRoute(partialRoute)) ?? [];
      this.calendarItems = data.calendarItems?.map(calendarItem => new CalendarItem(calendarItem)) ?? [];
    }
  }
}

export class PartialRoute extends Immerable implements EntityDto<string> {
  id!: string;
  routeId!: string;
  harbourId!: string;
  harbourName!: string;
  order!: number;
  priceCatalogs!: Array<PriceCatalog>;
  schedules!: Array<RouteSchedule>;

  constructor(data?: PartialRouteDto) {
    super();
    if (data) {
      this.id = data.id;
      this.routeId = data.routeId;
      this.harbourId = data.harbourId;
      this.harbourName = data.harbourName;
      this.order = data.order;
      this.priceCatalogs = data.priceCatalogs?.map(priceCatalog => new PriceCatalog(priceCatalog)) ?? [];
      this.schedules = data.schedules?.map(schedule => new RouteSchedule(schedule)) ?? [];
    }
  }
}

export class RouteSchedule extends Immerable implements EntityDto<string> {
  id!: string;
  partialRouteId!: string;
  order!: number;
  scheduleType!: ScheduleType;
  arrival?: DateTime;
  departure?: DateTime;

  constructor(data?: RouteScheduleDto) {
    super();
    if (data) {
      this.id = data.id;
      this.partialRouteId = data.partialRouteId;
      this.order = data.order;
      this.scheduleType = data.scheduleType;
      this.arrival = data.arrival ? DateTime.fromFormat(data.arrival, 'hh:mm:ss') : undefined;
      this.departure = data.departure ? DateTime.fromFormat(data.departure, 'hh:mm:ss') : undefined;
    }
  }

  toJson(): RouteScheduleDto {
    return {
      ...this,
      arrival: this.arrival?.toFormat('hh:mm:ss') ?? null,
      departure: this.departure?.toFormat('hh:mm:ss') ?? null
    }
  }
}

export class PriceCatalog extends Immerable implements EntityDto<string> {
  id!: string;
  fromPartialRouteId!: string;
  toPartialRouteId!: string;
  price!: number;

  constructor(data?: PriceCatalogDto) {
    super();
    if (data) {
      this.id = data.id;
      this.fromPartialRouteId = data.fromPartialRouteId;
      this.toPartialRouteId = data.toPartialRouteId;
      this.price = data.price;
    }
  }
}