import I18n from 'i18n-js';
import { FormEvent, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { setCheckoutFormAction } from '../../../redux/slices/checkout-form.slice';
import { ICheckoutForm } from '../../../shared/interface';

type Props = {
  submit(form: ICheckoutForm): Promise<string | undefined>;
  loading: boolean;
}

export const CheckoutForm = ({ submit, loading }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [form, setForm] = useState<ICheckoutForm>(useAppSelector(state => state.checkoutForm));
  const [message, setMessage] = useState<string>();
  const [termsAndConditionsModalOpen, setTermsAndConditionsModalOpen] = useState<boolean>(false);

  const handleChange = (event: FormEvent<HTMLElement>) => {
    const field = (event.target as any).id;

    switch (field) {
      case 'payNowTrue':
        setForm(prev => ({ ...prev, payNow: true }));
        break;

      case 'payNowFalse':
        setForm(prev => ({ ...prev, payNow: false }));
        break;

      case 'termsAndConditions':
        setForm(prev => ({ ...prev, termsAndConditions: (event.target as any).checked }));
        break;

      default:
        setForm(prev => ({ ...prev!, [field]: (event.target as any).value }))
        break;
    }
  }

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    dispatch(setCheckoutFormAction(form));

    setMessage(await submit(form));
  }

  const buttonContent = () => {
    return loading
      ? <Spinner animation='border' variant={'light'} style={{ width: '1rem', height: '1rem' }}></Spinner>
      : form.payNow ? I18n.t('::Continue') : I18n.t('::ReserveAndPayLater')
  }

  return (
    <>
      <Card className='my-3'>
        <Card.Header className='d-flex justify-content-between'>
          <h5>{I18n.t('::Contact')}</h5>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={onSubmit}>
            <Row>
              <Form.Group as={Col} xs={12} md={6} controlId='name'>
                <Form.Label>{ I18n.t('::FirstName') }*</Form.Label>
                <Form.Control type="text" required value={form.name} onChange={handleChange} />
              </Form.Group>

              <Form.Group as={Col} xs={12} md={6} controlId='surname'>
                <Form.Label>{ I18n.t('::LastName') }*</Form.Label>
                <Form.Control type="text" required value={form.surname} onChange={handleChange} />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} xs={12} md={6} controlId='email' className='mt-3'>
                <Form.Label>{ I18n.t('::Email') }*</Form.Label>
                <Form.Control type="email" required value={form.email} onChange={handleChange} />
              </Form.Group>

              <Form.Group as={Col} xs={12} md={6} controlId='phoneNumber' className='mt-3'>
                <Form.Label>{ I18n.t('::Phone') }*</Form.Label>
                <Form.Control type="phone" required value={form.phoneNumber} onChange={handleChange} />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className='mt-3' controlId='returnTrip'>
                <Form.Check
                  className='font-weight-normal'
                  type={'radio'}
                  id={'payNowFalse'}
                  name={'payNow'}
                  label={ I18n.t('::PayLater') }
                  checked={!form.payNow}
                  onChange={handleChange}
                />
                <Form.Check
                  className='font-weight-normal'
                  type={'radio'}
                  id={'payNowTrue'}
                  name={'payNow'}
                  label={ I18n.t('::PayNow') }
                  checked={form.payNow}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className='mt-3' controlId='termsAndConditions'>
                <Form.Check
                  type={'checkbox'}
                  label={<p className='fw-normal'>
                    { I18n.t('::TermsAndConditions1') }
                    <span className='ms-1 link-primary cursor-pointer' onClick={(e) => {
                      e.preventDefault();
                      setTermsAndConditionsModalOpen(true);
                    }} >
                      { I18n.t('::TermsAndConditions2') }
                    </span>
                  </p>}
                  required
                  checked={form.termsAndConditions}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            <Row className='mt-3'>
              <Col xs={12} className='d-flex flex-column flex-lg-row'>
                <Button type='button' variant='secondary' className='ms-lg-auto my-1 my-lg-0 me-lg-1' onClick={() => navigate(-1)}>
                  { I18n.t('::Back') }
                </Button>
                <Button type='submit' variant='primary' className='my-1 my-lg-0 ms-lg-1' disabled={loading}>
                  {buttonContent()}
                </Button>
              </Col>
            </Row>

            {message && (
              <Row>
                <p className='text-danger text-end mt-2'>
                  {message}
                </p>
              </Row>
            )}

          </Form>
        </Card.Body>
      </Card>

      <Modal size='xl' show={termsAndConditionsModalOpen} onHide={() => setTermsAndConditionsModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>
              {I18n.t('::TermsAndConditions')}
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='white-space-pre-wrap'>
          <h5>
            {I18n.t('::TermsAndConditions1:Title')}
          </h5>
          <p>
            {I18n.t('::TermsAndConditions1:Text')}
          </p>

          <h5>
            {I18n.t('::TermsAndConditions2:Title')}
          </h5>
          <p>
            {I18n.t('::TermsAndConditions2:Text')}
          </p>

          <h5>
            {I18n.t('::TermsAndConditions3.1:Title')}
          </h5>
          <p>
            {I18n.t('::TermsAndConditions3.1:Text')}
          </p>

          <h5>
            {I18n.t('::TermsAndConditions3.2:Title')}
          </h5>
          <p>
            {I18n.t('::TermsAndConditions3.2:Text')}
          </p>

          <h5>
            {I18n.t('::TermsAndConditions3.3.1:Title')}
          </h5>
          <p>
            {I18n.t('::TermsAndConditions3.3.1:Text')}
          </p>

          <h5>
            {I18n.t('::TermsAndConditions3.3.2:Title')}
          </h5>
          <p>
            {I18n.t('::TermsAndConditions3.3.2:Text')}
          </p>

          <h5>
            {I18n.t('::TermsAndConditions3.3.3:Title')}
          </h5>
          <p>
            {I18n.t('::TermsAndConditions3.3.3:Text')}
          </p>

          <h5>
            {I18n.t('::TermsAndConditions3.3.4:Title')}
          </h5>
          <p>
            {I18n.t('::TermsAndConditions3.3.4:Text')}
          </p>

          <h5>
            {I18n.t('::TermsAndConditions5:Title')}
          </h5>
          <p>
            {I18n.t('::TermsAndConditions5:Text')}
          </p>

          <h5>
            {I18n.t('::TermsAndConditions6:Title')}
          </h5>
          <p>
            {I18n.t('::TermsAndConditions6:Text')}
          </p>

          <h5>
            {I18n.t('::TermsAndConditions7:Title')}
          </h5>
          <p>
            {I18n.t('::TermsAndConditions7:Text')}
          </p>

          <h5>
            {I18n.t('::TermsAndConditions8:Title')}
          </h5>
          <p>
            {I18n.t('::TermsAndConditions8:Text')}
          </p>

          <h5>
            {I18n.t('::TermsAndConditions9:Title')}
          </h5>
          <p>
            {I18n.t('::TermsAndConditions9:Text')}
          </p>

          <h5>
            {I18n.t('::TermsAndConditions10.1:Title')}
          </h5>
          <p>
            {I18n.t('::TermsAndConditions10.1:Text')}
          </p>

          <h5>
            {I18n.t('::TermsAndConditions10.2:Title')}
          </h5>
          <p>
            {I18n.t('::TermsAndConditions10.2:Text')}
          </p>

          <h5>
            {I18n.t('::TermsAndConditions10.3:Title')}
          </h5>
          <p>
            {I18n.t('::TermsAndConditions10.3:Text')}
          </p>

          <h5>
            {I18n.t('::TermsAndConditions10.4:Title')}
          </h5>

          <p>
            {I18n.t('::TermsAndConditions10.4:Text')}
          </p>

          <h5>
            {I18n.t('::TermsAndConditions:Appendix1:Title')}
          </h5>
          <p>
            {I18n.t('::TermsAndConditions:Appendix1:Text1')}
          </p>
          <p>
            {I18n.t('::TermsAndConditions:Appendix1:Text2')}
          </p>
          <p>
            {I18n.t('::TermsAndConditions:Appendix1:Text3')}
          </p>
          <p>
            {I18n.t('::TermsAndConditions:Appendix1:Text3.1')}
          </p>
          <p>
            {I18n.t('::TermsAndConditions:Appendix1:Text3.2')}
          </p>
          <p>
            {I18n.t('::TermsAndConditions:Appendix1:Text3.3')}
          </p>
          <p>
            {I18n.t('::TermsAndConditions:Appendix1:Text3.4')}
          </p>
          <p>
            {I18n.t('::TermsAndConditions:Appendix1:Text3.5')}
          </p>
          <p>
            {I18n.t('::TermsAndConditions:Appendix1:Text3.6')}
          </p>
          <p>
            {I18n.t('::TermsAndConditions:Appendix1:Text4')}
          </p>
        </Modal.Body>

      </Modal>
    </>
  );
}