import { Store } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { RootState } from '../redux/store';
import { Language } from '../shared/interface/language';
import { TENANT_HEADER } from './api-consts';
import api from './axios';

export function initAPIInterceptor(store: Store<RootState>) {
  api.interceptors.request.use(
    async request => {
      const {
        settings: { language, tenantId, appConfig },
      } = store.getState();
      const selectedLanguage = language || (appConfig?.localization?.currentCulture as Language)?.cultureName

      if (!request.headers['Accept-Language'] && selectedLanguage) {
        request.headers['Accept-Language'] = selectedLanguage;
      }

      if (!request.headers[TENANT_HEADER] && tenantId) {
        request.headers[TENANT_HEADER] = tenantId;
      }

      return request;
    },
    error => console.error(error)
  );

  api.interceptors.response.use(
    response => response,
    (error: AxiosError<any>) => {
      return Promise.reject(error);
    }
  );
}