import axios from 'axios';
import { getEnvVars } from '../../environment';

const { api_url } = getEnvVars();

const axiosInstance = axios.create({
  baseURL: api_url,
  headers: {
    common: {
      'Content-Type': 'application/json',
    }
  }
});

export default axiosInstance;