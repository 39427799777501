import { useEffect } from 'react';
import { Loading } from '../app/components/loading/Loading';
import { Navigation } from './components/navigation/Navigation';
import { useAppDispatch, useAppSelector } from './hooks';
import { fetchAppConfigAsync, setBookingChannel, setLanguage, setRoute, setTenant } from './redux/slices/settings.slice';
import { store } from './redux/store';
import { initAPIInterceptor } from './service/interceptor';
import './shared/i18n/i18n';
import { initI18n } from './shared/i18n/i18n';

export type AppProps = {
  tenantId: string;
  routeId: string;
  bookingChannel: string;
  language: string;
}

initI18n();
initAPIInterceptor(store);

function App({ tenantId, routeId, bookingChannel, language }: AppProps) {
  const dispatch = useAppDispatch();
  const storedTenant = useAppSelector(state => state.settings.tenantId);
  const appConfig = useAppSelector(state => state.settings.appConfig);

  const isReady = !!appConfig && !!storedTenant;

  useEffect(() => {
    dispatch(setRoute(routeId));
  }, [dispatch, routeId]);

  useEffect(() => {
    dispatch(setTenant(tenantId));
    dispatch(fetchAppConfigAsync())
  }, [dispatch, tenantId]);

  useEffect(() => {
    dispatch(setBookingChannel(bookingChannel));
  }, [dispatch, bookingChannel]);

  useEffect(() => {
    if (language) {
      dispatch(setLanguage(language));
    }
  }, [dispatch, language]);

  if (isReady)
    return <Navigation />

  return (
    <Loading>
      <span>Loading widget...</span>
    </Loading>
  );
}

export default App;
