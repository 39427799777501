import I18n from 'i18n-js';
import { DateTime } from 'luxon';
import { FormEvent, FormEventHandler, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector, useRoute } from '../../../hooks';
import { resetSearchAction, searchBoatTripsAction, setSearchParamsAction } from '../../../redux/slices/boat-trip-search.slice';
import { distinct } from '../../../shared/helpers/array-helpers';
import { DAY_BREAK_HOUR, findFirstAvailableDate } from '../../../shared/helpers/calendar-helpers';
import { LookupDto, SearchParams } from '../../../shared/interface';
import { BoatTripSearchResult } from './Result';


export const BoatTripSearch = () => {
  const dispatch = useAppDispatch();
  const { routeId } = useParams();
  const route = useRoute(routeId!);
  const staticRoute = useAppSelector(state => state.settings.routeId);
  const searchParams = useAppSelector(state => state.boatTripsSearch.data.searchParams);

  const [formValue, setFormValue] = useState<SearchParams>(searchParams ?? { routeId: routeId ?? '', fromHarbourId: '', toHarbourId: '', departureDate: '', numberOfAdults: 1, numberOfChildren: 0, returnTrip: true });
  const [harbourLookup, setHarbourLookup] = useState<Array<LookupDto<string>>>([]);
  const [errors, setErrors] = useState<Record<string, string | undefined>>({});
  const todayDate = DateTime.now();
  const today = todayDate.toFormat('yyyy-MM-dd');

  useEffect(() => {
    if (route?.partialRoutes.length) {
      const lookup = distinct<LookupDto<string>>(
        route.partialRoutes.map(x => ({ id: x.harbourId, displayName: x.harbourName })),
        x => x.id
      );

      setHarbourLookup(lookup);
    } else {
      setHarbourLookup([]);
    }

    if (route && route.id !== formValue.routeId)
      setFormValue(prev => ({ ...prev, departureDate: '', returnDate: '' }));
  }, [route, formValue.routeId]);

  useEffect(() => {
    if (harbourLookup.length && route?.partialRoutes?.length) {
      let foundDate = findFirstAvailableDate(route.calendarItems);

      if (!foundDate) {
        foundDate = todayDate.hour >= DAY_BREAK_HOUR ? todayDate.plus({ days: 1 }) : todayDate;
      }

      const firstAvailableDate = foundDate.toFormat('yyyy-MM-dd');

      setFormValue(prev => {
        let departureDate: string, returnDate: string;

        if (prev.routeId === route?.id) {
          departureDate = prev.departureDate || firstAvailableDate;
          returnDate = prev.returnTrip ? prev.returnDate || firstAvailableDate : '';
        } else {
          departureDate = returnDate = firstAvailableDate;
        }

        return {
          routeId: route.id,
          fromHarbourId: harbourLookup.find(x => x.id === prev.fromHarbourId)?.id ?? harbourLookup[0].id,
          toHarbourId: harbourLookup.find(x => x.id === prev.toHarbourId)?.id ?? harbourLookup[harbourLookup.length - 1].id,
          returnTrip: prev?.returnTrip ?? true,
          departureDate,
          returnDate,
          numberOfAdults: prev?.numberOfAdults ?? 1,
          numberOfChildren: prev?.numberOfChildren ?? 0
        };
      });
    }
  }, [route, harbourLookup]);

  useEffect(() => {
    if (formValue?.returnTrip) {
      if (formValue?.returnDate) {
        const departureDate = DateTime.fromFormat(formValue.departureDate, 'yyyy-MM-dd');
        const returnDate = DateTime.fromFormat(formValue.returnDate, 'yyyy-MM-dd');

        var diff = returnDate.diff(departureDate, 'days');

        if (diff.days < 0) {
          setErrors(e => ({ ...e, returnDate: I18n.t('::ThisFieldIsRequired.') }));
        } else {
          setErrors(e => ({ ...e, returnDate: undefined }));
        }
      } else {
        setErrors(e => ({ ...e, returnDate: I18n.t('::ThisFieldIsRequired.') }));
      }
    } else {
      setErrors(e => ({ ...e, returnDate: undefined }));
    }
  }, [formValue]);

  const handleChange = (event: FormEvent<HTMLElement>) => {
    const field = (event.target as any).id;

    switch (field) {
      case 'returnTripTrue':
        setFormValue(prev => ({ ...prev, returnTrip: true, returnDate: prev.departureDate }));
        break;

      case 'returnTripFalse':
        setFormValue(prev => ({ ...prev, returnTrip: false, returnDate: '' }));
        break;

      case 'fromHarbourId':
        const fromHarbourId = (event.target as any).value;
        let toHarbourId = formValue.toHarbourId;

        if (fromHarbourId === toHarbourId) {
          toHarbourId = harbourLookup.find(x => x.id !== fromHarbourId)?.id ?? '';
        }

        setFormValue(prev => ({ ...prev, fromHarbourId, toHarbourId }));
        break;

      case 'numberOfAdults':
      case 'numberOfChildren':
        const value = (event.target as HTMLInputElement).valueAsNumber;

        setFormValue(prev => ({ ...prev, [field]: value }));
        break;

      case 'departureDate':
        const departureDate = (event.target as any).value;
        const returnDate = formValue.returnTrip ? departureDate : undefined;

        setFormValue(prev => ({ ...prev, departureDate, returnDate }));
        break;

      default:
        setFormValue(prev => ({ ...prev!, [field]: (event.target as any).value }))
        break;
    }

    dispatch(resetSearchAction(null));
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    if (form.checkValidity() === true) {
      dispatch(setSearchParamsAction(formValue));
      dispatch(searchBoatTripsAction(formValue!));
    }
  };

  if (!route) {
    return (
      <div className='d-flex flex-column align-items-center mt-3'>
        <Spinner animation='border' variant={'primary'} style={{ width: '4rem', height: '4rem' }}></Spinner>
        <h4 className='mt-3'>{I18n.t('::LoadingWidget')}</h4>
      </div>
    );
  }

  return (
    <>
      {!staticRoute && route && <h2>{route.name}</h2>}

      {formValue && <Form noValidate onSubmit={handleSubmit}>
        <Form.Group className='mt-3' controlId='returnTrip'>
          <Form.Check
            className='font-weight-normal'
            type={'radio'}
            id={'returnTripTrue'}
            name={'returnTrip'}
            label={I18n.t("::RoundTrip")}
            checked={formValue.returnTrip}
            onChange={handleChange}
          />
          <Form.Check
            className='font-weight-normal'
            type={'radio'}
            id={'returnTripFalse'}
            name={'returnTrip'}
            label={I18n.t("::OneWayTrip")}
            checked={!formValue.returnTrip}
            onChange={handleChange}
          />
        </Form.Group>

        <Row>
          <Form.Group as={Col} xs={12} md={6} className='mt-3' controlId="fromHarbourId">
            <Form.Label>{I18n.t('::From')}</Form.Label>
            <Form.Select required value={formValue.fromHarbourId} onChange={handleChange}>
              {harbourLookup.map(x =>
                <option key={x.id} value={x.id}>
                  {x.displayName}
                </option>
              )}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} xs={12} md={6} className='mt-3' controlId="departureDate">
            <Form.Label>{I18n.t('::Departure')}</Form.Label>
            <Form.Control type="date" required min={today} value={formValue.departureDate} onChange={handleChange} />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} xs={12} md={6} className="mt-3" controlId="toHarbourId">
            <Form.Label required>{I18n.t('::To')}</Form.Label>
            <Form.Select required value={formValue.toHarbourId} onChange={handleChange}>
              {harbourLookup
                .filter(x => x.id !== formValue.fromHarbourId)
                .map(x =>
                  <option key={x.id} value={x.id}>
                    {x.displayName}
                  </option>
                )}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} xs={12} md={6} className="mt-3" controlId="returnDate">
            <Form.Label>{I18n.t('::ReturnTrip')}</Form.Label>
            <Form.Control
              type="date"
              required={formValue.returnTrip}
              disabled={!formValue.returnTrip}
              min={formValue.departureDate}
              isInvalid={!!errors.returnDate}
              value={formValue.returnDate}
              onChange={handleChange}
            />
            <Form.Control.Feedback type={'invalid'}>
              {errors.returnDate}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} xs={6} lg={3} className="mt-3" controlId="numberOfAdults">
            <Form.Label>{I18n.t('::Booking:Adult')}</Form.Label>
            <Form.Control type="number" min={formValue.numberOfChildren === 0 ? 1 : 0} max={route?.capacity} value={formValue.numberOfAdults} onChange={handleChange} />
          </Form.Group>

          <Form.Group as={Col} xs={6} lg={3} className="mt-3" controlId="numberOfChildren">
            <Form.Label>{I18n.t('::Booking:Child')}</Form.Label>
            <Form.Control type="number" min={0} max={route?.capacity} value={formValue.numberOfChildren} onChange={handleChange} />
          </Form.Group>
          <Col className='d-flex justify-content-end align-items-end mt-3 mt-lg-0' xs={12} lg={6}>
            <Button className="mt-auto" variant="primary" type="submit">
              {I18n.t('::Search')}
            </Button>
          </Col>
        </Row>
      </Form>}

      <BoatTripSearchResult />
    </>
  );
};