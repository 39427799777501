import I18n from 'i18n-js';
import { useContext, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { BamboraContext, IBamboraContext, PaymentStatus } from '../../contexts/Bambora.context';

export const Checkout = () => {
  const { session } = useParams();
  const bamboraContainer = useRef<HTMLDivElement>(null);
  const { createCheckoutSession, cancelCheckoutSession, resetStatusToIdle, status } = useContext(BamboraContext) as IBamboraContext;
  const navigate = useNavigate();

  useEffect(() => {
    if (status === PaymentStatus.Success) {
      navigate('/checkout/confirmation');
    } else if (status === PaymentStatus.Cancel) {
      resetStatusToIdle()
      navigate(-1);
    }
  }, [navigate, status]);

  useEffect(() => {
    if (session && bamboraContainer.current) {
      createCheckoutSession(session, bamboraContainer.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  const button = () => {
    switch (status) {
      case PaymentStatus.Idle:
      case PaymentStatus.InProgress:
      case PaymentStatus.Failure:
        return <Button variant="light" className='ms-auto me-auto' onClick={() => cancelCheckoutSession()}>{I18n.t('::Cancel')}</Button>;

      default:
        return null;
    }
  }

  return (
    <div className='d-flex flex-column'>
      <div ref={bamboraContainer} style={{ height: 500 }}></div>
      {button()}
    </div>
  );
}