import I18n from 'i18n-js';
import { useContext, useEffect } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { BamboraContext, IBamboraContext } from '../../contexts/Bambora.context';
import { useAppSelector } from '../../hooks';
import { clearUserData } from '../../redux/store';
import { ICheckoutForm } from '../../shared/interface';
import { OrderSummary } from './components/OrderSummary';

export const BookingConfirmation = () => {
  const navigate = useNavigate();
  const staticRoute = useAppSelector(state => state.settings.routeId);
  const { data: routes } = useAppSelector(state => state.routes);
  const { resetStatusToIdle } = useContext(BamboraContext) as IBamboraContext;
  const { email } = useAppSelector(state => state.checkoutForm) as ICheckoutForm;

  const onClick = () => {
    clearUserData();

    navigate(`/${staticRoute ?? routes[0].id}`);
  }

  useEffect(() => {
    resetStatusToIdle();
  }, []);

  return (
    <>
      <div className='bg-dark py-5'>
        <h1 className='display-4 fw-bold text-light text-center'>
          {I18n.t('::OrderConfirmation:Title')}
        </h1>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} md={6}>
            <Card className='my-3'>
              <Card.Body>
                <Row>
                  <Col xs={12}>
                    <p>
                      {I18n.t('::OrderConfirmation:Message', { 0: email })}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant={'primary'} onClick={onClick}>
                      {I18n.t('::Back')}
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} md={6}>
            <OrderSummary />
          </Col>
        </Row>
      </Container>
    </>
  )
}