import { DateTime, WeekdayNumbers } from 'luxon';
import { CalendarItem, DayOfWeek } from '../interface/calendar/models';

/**
 * After this hour of the day, the default date for a calendar item is tomorrow.
 */
export const DAY_BREAK_HOUR = 17;

/**
 * Find the closest future date in a list of calendar items.
 * @param calendarItems
 * @returns
 */
export const findFirstAvailableDate = (
  calendarItems: CalendarItem[]
): DateTime | undefined => {
  let firstValidDate = DateTime.now();

  if (firstValidDate.hour >= DAY_BREAK_HOUR)
    firstValidDate = firstValidDate.plus({ days: 1 });

  const result = calendarItems
    //.sort((a, b) => a.start.diff(b.start).valueOf())
    // Filter out all items that are in the past
    .filter((item) => {
      const hasNotEnded =
        !item.end || item.end.diff(firstValidDate).toMillis() > 0;

      return hasNotEnded;
    })
    // Return the first date that is in the future and is on a valid weekday
    .map((item) => {
      let date =
        item.start.diffNow().toMillis() < 0 ? firstValidDate : item?.start;

      while (!item.weekDays.includes(luxonWeekDayToDayOfWeek[date.weekday])) {
        if (item.end && date.diff(item.end).toMillis() > 0) {
          return undefined;
        }

        date = date.plus({ days: 1 });
      }

      return date;
    })
    .find(Boolean);

  return result;
};

const DayOfWeekToLuxonWeeDay: Record<DayOfWeek, WeekdayNumbers> = {
  [DayOfWeek.Monday]: 1,
  [DayOfWeek.Tuesday]: 2,
  [DayOfWeek.Wednesday]: 3,
  [DayOfWeek.Thursday]: 4,
  [DayOfWeek.Friday]: 5,
  [DayOfWeek.Saturday]: 6,
  [DayOfWeek.Sunday]: 7,
};

const luxonWeekDayToDayOfWeek: Record<WeekdayNumbers, DayOfWeek> = {
  1: DayOfWeek.Monday,
  2: DayOfWeek.Tuesday,
  3: DayOfWeek.Wednesday,
  4: DayOfWeek.Thursday,
  5: DayOfWeek.Friday,
  6: DayOfWeek.Saturday,
  7: DayOfWeek.Sunday,
};
