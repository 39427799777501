import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { getEnvVars } from '../../environment';
import boatTripsSearchSlice, { resetSearchAction } from './slices/boat-trip-search.slice';
import routesSlice from './slices/route.slice';
import settingsSlice from './slices/settings.slice';
import bookingSlice, { resetBookingAction } from './slices/booking.slice';
import checkoutForm, { resetCheckoutFormAction } from './slices/checkout-form.slice';

export const store = configureStore({
  reducer: {
    settings: settingsSlice,
    routes: routesSlice,
    boatTripsSearch: boatTripsSearchSlice,
    booking: bookingSlice,
    checkoutForm: checkoutForm
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
  devTools: getEnvVars().prod === false,
});

export const clearUserData = () => {
  store.dispatch(resetSearchAction(null));
  store.dispatch(resetBookingAction(null));
  store.dispatch(resetCheckoutFormAction(null));
}

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
