import i18n from 'i18n-js';
import { getEnvVars } from '../../../environment';

const { localization } = getEnvVars();

export const initI18n = () => {
  i18n.defaultSeparator = '::';

  const cloneT = i18n.t;
  i18n.t = (key, ...args) => {
    if (key.slice(0, 2) === '::') {
      key = localization.defaultResourceName + key;
    }
    return cloneT(key, ...args);
  };
};