
const ENV = {
  DEV: {
    prod: false,
    api_url: 'https://localhost:44393/api',
    localization: {
      defaultResourceName: 'SemesterbokningenWidget',
    },
  },
  PROD: {
    prod: true,
    api_url: 'https://api.semesterbokningen.se/api',
    localization: {
      defaultResourceName: 'SemesterbokningenWidget',
    },
  }
}

export const getEnvVars = () => {
  switch (process.env.NODE_ENV) {
    case 'production':
      return ENV.PROD;

    default:
      return ENV.DEV
  }
}