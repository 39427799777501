import { createAsyncThunk, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { ROUTES } from '../../service/api-consts';
import { getRoute, getRoutes } from '../../service/routes';
import { IStateSlice, LoadingState, Route } from '../../shared/interface';
import { cancelIfPending } from '../helpers/cancel-request-if-pending';
import { RootState } from '../store';

export type RouteSlice = IStateSlice<Array<Route>>;

export const fetchRoutesAction = createAsyncThunk<Array<Route>, void, { state: RootState }>(
  ROUTES,
  getRoutes,
  { condition: cancelIfPending('routes') }
);

export const fetchRouteAction = createAsyncThunk<Route, string, { state: RootState }>(
  ROUTES + '/:id',
  getRoute,
  { condition: cancelIfPending('routes') }
);

const routesSlice = createSlice<RouteSlice, SliceCaseReducers<RouteSlice>, 'routes'>({
  name: 'routes',
  initialState: { loading: LoadingState.IDLE, data: [] },
  reducers: {
    reset: () => ({ loading: LoadingState.IDLE, data: [] }),
  },
  extraReducers: builder => {
    // Fetch routes list
    builder.addCase(
      fetchRoutesAction.pending,
      state => {
        state.loading = LoadingState.PENDING;
      }
    );

    builder.addCase(
      fetchRoutesAction.fulfilled,
      (state, action) => {
        state.loading = LoadingState.COMPLETE;
        state.data = action.payload;
      }
    );

    builder.addCase(
      fetchRoutesAction.rejected,
      (state, action) => {
        state.loading = LoadingState.FAILED;
        state.error = action.error.message;
        state.data = [];
      }
    );

    // Fetch single route
    builder.addCase(
      fetchRouteAction.pending,
      (state) => {
        state.loading = LoadingState.PENDING;
      }
    );

    builder.addCase(
      fetchRouteAction.fulfilled,
      (state, action) => {
        state.loading = LoadingState.COMPLETE;
        const routeIndex = state.data.findIndex(route => route.id === action.meta.arg);

        if (routeIndex === -1) {
          state.data.push(action.payload);
        } else {
          state.data[routeIndex] = action.payload;
        }
      }
    );

    builder.addCase(
      fetchRouteAction.rejected,
      (state, action) => {
        console.error(action.error);
        state.loading = LoadingState.FAILED;
        state.error = action.error.message;
      }
    );
  }
});

export const { reset: resetRoutesAction } = routesSlice.actions;
export default routesSlice.reducer;