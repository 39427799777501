import { fetchRouteAction, fetchRoutesAction } from '../redux/slices/route.slice';
import { RootState } from '../redux/store';
import { LoadingState, Route } from '../shared/interface';
import { useAutoFetchSelector } from './use-auto-fetch-selector';

const selectRoutes = (state: RootState) => state.routes;

export const useRoutes = () => useAutoFetchSelector(
  selectRoutes,
  fetchRoutesAction()
);

export const useRoute = (id: string): Route | undefined => useAutoFetchSelector<Array<Route>>(
  selectRoutes,
  fetchRouteAction(id),
  state => {
    if (state.loading === LoadingState.IDLE) {
      return true;
    }

    const route = state.data.find(route => route.id === id);

    if (route?.partialRoutes.length) {
      return false;
    }

    return state.loading === LoadingState.COMPLETE;
  }
).data.find(route => route.id === id);