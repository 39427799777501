import { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { IStateSlice, LoadingState, PickKeysOfType } from '../../shared/interface';
import { RootState } from '../store';

/**
 * Cancel request to fetch data if there is already a request pending
 */
export function cancelIfPending(key: PickKeysOfType<RootState, IStateSlice<any>>): any {
  return (arg: any, { getState }: BaseThunkAPI<RootState, void>) => {
    const state = getState()[key];

    return state.loading !== LoadingState.PENDING;
  }
};
