import I18n from 'i18n-js';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector, useRoute, useRoutePrice } from '../../../hooks';
import { setBooking } from '../../../redux/slices/booking.slice';
import { Booking, SearchParams } from '../../../shared/interface';

export interface BookingCardProps {
  routeName: string;
  booking: Booking;
}

export const BookingCard = ({ routeName, booking }: BookingCardProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const searchParams = useAppSelector(state => state.boatTripsSearch.data.searchParams) as SearchParams;

  const { departureTrip, returnTrip } = booking;
  const [departureDuration, setDepartureDuration] = useState<string>('');
  const [returnDuration, setReturnDuration] = useState<string>('');
  const [departureFullyBooked, setDepartureFullyBooked] = useState<boolean>(false);
  const [returnFullyBooked, setReturnFullyBooked] = useState<boolean>(false);

  const { routeId } = useParams();
  const route = useRoute(routeId!);
  const price = useRoutePrice(booking);

  useEffect(() => {
    const departureDuration = departureTrip.arrivalSchedule.arrival!.diff(departureTrip.departureSchedule.departure!).shiftTo('hours', 'minutes');
    const returnDuration = returnTrip?.arrivalSchedule.arrival!.diff(returnTrip.departureSchedule.departure!).shiftTo('hours', 'minutes');

    setDepartureDuration(`${departureDuration.hours}${I18n.t('::Units:Hour')} ${departureDuration.minutes}${I18n.t('::Units:Minute')}`);

    if (returnDuration) {
      setReturnDuration(`${returnDuration.hours}${I18n.t('::Units:Hour')} ${returnDuration.minutes}${I18n.t('::Units:Minute')}`);
    }
  }, [departureTrip, returnTrip]);

  useEffect(() => {
    if (route) {
      const totalBooking = searchParams.numberOfAdults + searchParams.numberOfChildren;
      const availableCapacityDeparture = route.capacity - booking.departureTrip.bookedCapacity;
      const availableCapacityReturn = route.capacity - (booking.returnTrip?.bookedCapacity ?? 0);

      setDepartureFullyBooked(totalBooking > availableCapacityDeparture);
      setReturnFullyBooked(totalBooking > availableCapacityReturn);
    }
  }, [searchParams, booking, route]);

  const book = async () => {
    dispatch(setBooking(booking));

    navigate('/checkout/order');
  }

  return (
    <Card className='booking-card my-3'>
      <Card.Header className='d-flex justify-content-between'>
        <>
          <h5>{routeName}</h5>
          <h5>{price}kr</h5>
        </>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xs={'12'} md={'10'}>
            <Table size="sm" className='booking-card-table'>
              <tbody>
                <tr className={departureFullyBooked ? 'line-through' : ''}>
                  <td className='fw-bold'>
                    {departureTrip.departureHarbourName}
                  </td>
                  <td>
                    {departureTrip.departureSchedule.departure?.toFormat('HH:mm')}
                  </td>
                  <td>
                    <em className='bi bi-arrow-right'></em>
                  </td>
                  <td className='fw-bold'>
                    {departureTrip.arrivalHarbourName}
                  </td>
                  <td>
                    {departureTrip.arrivalSchedule.arrival?.toFormat('HH:mm')}
                  </td>
                  <td className='text-black-50 fst-italic'>
                    {departureDuration}
                  </td>
                  { departureFullyBooked &&
                    <td className='text-danger text-decoration-none'>
                      {route?.fullyBookedMessage ?? I18n.t('::FullyBooked')}
                    </td>
                  }
                </tr>
                {returnTrip &&
                  <tr className={returnFullyBooked ? 'line-through' : ''}>
                    <td className='fw-bold'>
                      {returnTrip.departureHarbourName}
                    </td>
                    <td>
                      {returnTrip.departureSchedule.departure?.toFormat('HH:mm')}
                    </td>
                    <td className='fw-bold'>
                      <em className='bi bi-arrow-right'></em>
                    </td>
                    <td className='fw-bold'>
                      {returnTrip.arrivalHarbourName}
                    </td>
                    <td>
                      {returnTrip.arrivalSchedule.arrival?.toFormat('HH:mm')}
                    </td>
                    <td className='text-black-50 fst-italic'>
                      {returnDuration}
                    </td>
                    { returnFullyBooked &&
                      <td className='text-danger text-decoration-none'>
                        {route?.fullyBookedMessage ?? I18n.t('::FullyBooked')}
                      </td>
                    }
                  </tr>}
              </tbody>
            </Table>
          </Col>
          <Col className='d-flex justify-content-end' xs={'12'} md={'2'}>
            <Button
              className='ms-auto mb-auto'
              size={'sm'}
              variant={'success'}
              disabled={departureFullyBooked || returnFullyBooked}
              onClick={book}>{I18n.t('::Book')}</Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}