import { BoatTripBookingSearch, BoatTripBookingSearchOutput, Booking, BookingQueryResult, ICheckoutForm, SearchParams } from '../shared/interface';
import { BOOKING, CREATE_CHECKOUT_SESSION, SEARCH } from './api-consts';
import axios from './axios';

export const search = async (input: SearchParams): Promise<BoatTripBookingSearch> => {
  const res = await axios.post<BoatTripBookingSearchOutput>(SEARCH, input);

  const searchResult: BoatTripBookingSearch = {
    ...res.data,
    departureSchedules: res.data.departureSchedules.map(x => new BookingQueryResult(x)),
    returnSchedules: res.data.returnSchedules.map(x => new BookingQueryResult(x))
  };

  return searchResult;
}

/**
 * Creates a new booking
 */
export const createBooking = async ({ departureTrip, returnTrip }: Booking, checkoutForm: ICheckoutForm, searchParams: SearchParams, bookingChannel: string): Promise<boolean> => {
  const res = await axios.post<string>(BOOKING, {
    departureTrip: departureTrip.toJson(),
    returnTrip: returnTrip ? returnTrip.toJson() : null,
    user: checkoutForm,
    searchParams,
    payNow: checkoutForm.payNow,
    bookingChannel
  });

  return res.status === 200;
}

/**
 * Creates a new booking and a Bambora checkout session
 */
 export const createBookingAndPayNow = async ({ departureTrip, returnTrip }: Booking, checkoutForm: ICheckoutForm, searchParams: SearchParams, bookingChannel: string): Promise<string> => {
  const res = await axios.post<string>(CREATE_CHECKOUT_SESSION, {
    departureTrip: departureTrip.toJson(),
    returnTrip: returnTrip ? returnTrip.toJson() : null,
    user: checkoutForm,
    searchParams,
    payNow: checkoutForm.payNow,
    bookingChannel
  });

  return res.data;
}