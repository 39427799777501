import I18n from 'i18n-js';
import { Card, Col, Row } from 'react-bootstrap';
import { useAppSelector, useRoutePrice } from '../../../hooks';
import { Booking, BookingQueryResult, SearchParams } from '../../../shared/interface';

export const OrderSummary = () => {
  const { departureDate, returnDate, numberOfAdults, numberOfChildren } = useAppSelector(state => state.boatTripsSearch.data.searchParams) as SearchParams;
  const booking = useAppSelector(state => state.booking) as Booking;
  const price = useRoutePrice(booking);

  const renderBooking = ({ departureHarbourName, arrivalHarbourName, departureSchedule }: BookingQueryResult, date: string) => {
    return (
      <Row className='my-2'>
        <Col className='d-flex flex-column'>
          <div>
            {departureHarbourName} - {arrivalHarbourName}
          </div>

          <div>
            {date} {departureSchedule.departure?.toFormat('HH:mm')}
          </div>

          {numberOfAdults > 0 &&
            <div>
              {numberOfAdults}x {I18n.t('::Booking:Adult')}
            </div>}

          {numberOfChildren > 0 &&
            <div>
              {numberOfChildren}x {I18n.t('::Booking:Child')}
            </div>}
        </Col>
      </Row>
    );
  }

  return booking && (
    <Card className='my-3'>
      <Card.Header className='bg-dark text-light'>
        <h5>{I18n.t('::Order')}</h5>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col className='d-flex justify-content-between'>
            <span className='fw-bold'>
              {booking.routeName}
            </span>
            <span className='fw-bold'>
              {price}kr
            </span>
          </Col>
        </Row>

        {renderBooking(booking.departureTrip, departureDate)}
        {booking.returnTrip && returnDate && renderBooking(booking.returnTrip, returnDate)}
      </Card.Body>
    </Card>
  );
}