import { useEffect } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../hooks';
import { RoutesDropdown } from './Routes-dropdown';

export const Header = () => {
  const navigate = useNavigate();
  const staticRoute = useAppSelector(state => state.settings.routeId);

  useEffect(() => {
    if (staticRoute) {
      navigate(staticRoute);
    }
  }, [navigate, staticRoute]);

  if (staticRoute)
    return null;

  return (
    <Navbar expand="lg">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <RoutesDropdown />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
